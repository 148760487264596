<template>
  <b-card title="Home Page">
    <b-tabs>
      <b-tab :active="currentRouteName=='add-upcoming-course'"
       @click="$router.push('/add-upcoming-course')">
        <template #title>
          <feather-icon icon="PlusIcon" />
          <span>New UpComing Course</span>
        </template>

      </b-tab>

      <b-tab  :active="currentRouteName=='upcoming-course'"
      @click="$router.push('/upcoming-course')">
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span>UpComing Course List</span>
        </template>
    
        <!-- <UpcomingCourse ref="allData"></UpcomingCourse> -->
      </b-tab>
      <b-tab :active="currentRouteName=='change-feature-course'"
       @click="$router.push('/change-feature-course')">
        <template #title>
          <feather-icon icon="PlusIcon" />
          <span>New Feature Course</span>
        </template>
    
        <!-- <ChangeFeatureCourse @add-feature="getData"></ChangeFeatureCourse> -->
      </b-tab>

      <b-tab :active="currentRouteName=='feature-course'"
       @click="$router.push('/feature-course')">
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span>Feature Course List</span>
        </template>
    
        <!-- <FeatureCourse ref="allDatafeature"></FeatureCourse> -->
      </b-tab>
    </b-tabs>
    <router-view v-slot="{ Component, route }">
      <AddUpComingCourse v-if="route.name === 'add-upcoming-course' "  />
      <router-view  v-else>
          <component :is="Component" />
        </router-view>
  </router-view>
 
  </b-card>
</template>

<script>
import { BTabs, BTab, BCardText, BCard } from "bootstrap-vue";

import { ref } from "@vue/composition-api";
import AddUpComingCourse from "./AddUpComingCourse.vue";
import UpcomingCourse from "./UpcomingCourse.vue";
import ChangeFeatureCourse from "./ChangeFeatureCourse.vue";
import FeatureCourse from "./FeatureCourse.vue";

export default {
  components: {
    BCard,
    BCardText,
    BTabs,
    BTab,
    FeatureCourse,
    AddUpComingCourse,
    UpcomingCourse,
    ChangeFeatureCourse,
  },
  setup() {
    const allDatafeature = ref();
    const allData = ref();

    const getData = () => {
     //   console.log("Eee");

      // allData.value.getDataUpComing();
      // allDatafeature.value.getDataFeature();
    };
    return {
      getData,
      allDatafeature,
      allData,
    }},
   computed: {
    currentRouteName() {
        return this.$route.name;
    }}}
</script>
